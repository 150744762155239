export interface MenuOption {
  featureKey: string;
  icon: string;
  name: string;
  route: string;
  intercomTarget: string;
  enabled: boolean;
  primary: boolean;
}

export const menuOptions: MenuOption[] = [
  {
    featureKey: 'dashboard',
    icon: 'home',
    name: $localize`Home`,
    intercomTarget: 'Home',
    route: 'home',
    enabled: true,
    primary: true,
  },
  {
    featureKey: 'analytics',
    icon: 'analytics',
    name: $localize`Analytics`,
    intercomTarget: 'Analytics',
    route: 'analytics',
    enabled: false,
    primary: true,
  },
  {
    featureKey: 'purchase',
    icon: 'shopping-basket',
    name: $localize`Purchase`,
    intercomTarget: 'Purchase',
    route: 'purchase',
    enabled: false,
    primary: true,
  },
  {
    featureKey: 'deliveries',
    icon: 'truck',
    name: $localize`Deliveries`,
    intercomTarget: 'Deliveries',
    route: 'deliveries',
    enabled: false,
    primary: true,
  },
  {
    featureKey: 'products',
    icon: 'box-closed',
    name: $localize`Products`,
    intercomTarget: 'Products',
    route: 'products',
    enabled: false,
    primary: true,
  },
  {
    featureKey: 'suppliers',
    icon: 'store',
    name: $localize`Suppliers`,
    intercomTarget: 'Suppliers',
    route: 'suppliers',
    enabled: false,
    primary: true,
  },
  {
    featureKey: 'promotions',
    icon: 'promotion-tag',
    name: $localize`Promotions`,
    intercomTarget: 'Promotions',
    route: 'promotions',
    enabled: false,
    primary: true,
  },
  {
    featureKey: 'settings',
    icon: 'settings-cog',
    name: $localize`Settings`,
    intercomTarget: 'Settings',
    route: 'settings',
    enabled: false,
    primary: false,
  },
];
