import { DateTime } from 'luxon';
import { FilterTypeOptionsV2 } from 'src/app/shared/components/design-system/data-table-v2/components/filter/model/filter-v2.model';
import {
  Filter,
  FilterTypes,
} from 'src/app/shared/components/design-system/data-table-v2/components/filter/model/filter.model';
import { SortOrder } from 'src/app/shared/components/design-system/data-table-v2/components/sort/model/sort.model';
import {
  ColumnFilterParam,
  ColumnOrder,
  DatatableColumnV2,
  DatatableColumnV2Groups,
  DatatablePagination,
  DatatableSort,
  buildGroupsMap,
} from 'src/app/shared/components/design-system/data-table-v2/model/data-table-v2.model';
import {
  ServiceRequestInfoV3FilteringType,
  ServiceRequestInfoV3Operators,
} from 'src/app/shared/components/design-system/data-table-v2/model/pageable-v2.model';
import { COLUMNS_GROUPS } from 'src/app/shared/models/datatable/v2/columns-groups.model';
import { STRINGS } from '../../../model/purchase-v3.strings';
import { ProposedOrdersStatus } from './proposed-orders.model';

export const defaultSort: DatatableSort = {
  [STRINGS.columns.purchase.PLANNED_DATE.key]: {
    field: STRINGS.columns.purchase.PLANNED_DATE.filterKey,
    order: SortOrder.ASC,
  },
};

const overdueDate = DateTime.now()
  .minus({ days: 1 })
  .toUTC()
  .toFormat('yyyy-MM-dd');

const todayDate = DateTime.now().toUTC().toFormat('yyyy-MM-dd');

export const COLUMNS_ORDER: ColumnOrder = {
  [STRINGS.columns.purchase.ORDER_MOMENT_ID.key]: 1,
  [STRINGS.columns.purchase.TYPE.key]: 2,
  [STRINGS.columns.purchase.TRIGGER_VALUE.key]: 3,
  [STRINGS.columns.purchaseEditor.NOTES.key]: 4,
  [STRINGS.columns.purchase.SUPPLIER_NAME.key]: 5,
  [STRINGS.columns.purchase.SUPPLIER_OWNER.key]: 6,
  [STRINGS.columns.purchase.PLANNED_DATE.key]: 7,
  [STRINGS.columns.purchase.SUPPLIER_CUTOFF.key]: 8,
  [STRINGS.columns.purchase.STATUS.key]: 9,
  [STRINGS.columns.purchase.NUMBER_OF_PRODUCTS.key]: 10,
  [STRINGS.columns.purchase.TOTAL_WEIGHT.key]: 11,
  [STRINGS.columns.purchase.TOTAL_VOLUME.key]: 12,
  [STRINGS.columns.purchase.TOTAL_VALUE.key]: 13,
  [STRINGS.columns.purchase.MINIMUM_ORDER_VALUE.key]: 14,
  [STRINGS.columns.purchase.ACTIONS.key]: 15,
};

export const defaultColumnsV2: DatatableColumnV2Groups<DatatableColumnV2> = {
  [COLUMNS_GROUPS.ORDER.key]: {
    groupKey: COLUMNS_GROUPS.ORDER.key,
    groupName: COLUMNS_GROUPS.ORDER.name,
    columns: {
      [STRINGS.columns.purchase.ORDER_MOMENT_ID.key]: {
        name: STRINGS.columns.purchase.ORDER_MOMENT_ID.key,
        displayName: STRINGS.columns.purchase.ORDER_MOMENT_ID.name,
        checked: true,
        type: FilterTypes.FILTER_NUMBER,
        selectable: true,
        disabled: false,
      },
      [STRINGS.columns.purchase.TYPE.key]: {
        name: STRINGS.columns.purchase.TYPE.key,
        displayName: STRINGS.columns.purchase.TYPE.name,
        checked: true,
        type: FilterTypes.FILTER_LIST,
        selectable: true,
        disabled: false,
      },
      [STRINGS.columns.purchase.TRIGGER_VALUE.key]: {
        name: STRINGS.columns.purchase.TRIGGER_VALUE.key,
        displayName: STRINGS.columns.purchase.TRIGGER_VALUE.name,
        checked: true,
        type: FilterTypes.FILTER_NUMBER,
        selectable: true,
        disabled: false,
      },
      [STRINGS.columns.purchase.PLANNED_DATE.key]: {
        name: STRINGS.columns.purchase.PLANNED_DATE.key,
        displayName: STRINGS.columns.purchase.PLANNED_DATE.name,
        checked: true,
        type: FilterTypes.FILTER_DATE,
        selectable: true,
        disabled: false,
      },
      [STRINGS.columns.purchase.STATUS.key]: {
        name: STRINGS.columns.purchase.STATUS.key,
        displayName: STRINGS.columns.purchase.STATUS.name,
        checked: true,
        type: FilterTypes.FILTER_LIST,
        selectable: true,
        disabled: false,
      },
      [STRINGS.columns.purchase.NUMBER_OF_PRODUCTS.key]: {
        name: STRINGS.columns.purchase.NUMBER_OF_PRODUCTS.key,
        displayName: STRINGS.columns.purchase.NUMBER_OF_PRODUCTS.name,
        checked: true,
        type: FilterTypes.FILTER_NUMBER,
        selectable: true,
        disabled: false,
      },
      [STRINGS.columns.purchase.TOTAL_VALUE.key]: {
        name: STRINGS.columns.purchase.TOTAL_VALUE.key,
        displayName: STRINGS.columns.purchase.TOTAL_VALUE.name,
        checked: true,
        type: FilterTypes.FILTER_NUMBER,
        selectable: true,
        disabled: false,
      },
    },
  },
  [COLUMNS_GROUPS.SUPPLIER.key]: {
    groupKey: COLUMNS_GROUPS.SUPPLIER.key,
    groupName: COLUMNS_GROUPS.SUPPLIER.name,
    columns: {
      [STRINGS.columns.purchase.NOTES.key]: {
        name: STRINGS.columns.purchase.NOTES.key,
        displayName: STRINGS.columns.purchase.NOTES.name,
        checked: true,
        type: null,
        selectable: true,
        disabled: false,
      },
      [STRINGS.columns.purchase.SUPPLIER_NAME.key]: {
        name: STRINGS.columns.purchase.SUPPLIER_NAME.key,
        displayName: STRINGS.columns.purchase.SUPPLIER_NAME.name,
        checked: true,
        type: FilterTypes.FILTER_TEXT,
        selectable: true,
        disabled: false,
      },
      [STRINGS.columns.purchase.SUPPLIER_OWNER.key]: {
        name: STRINGS.columns.purchase.SUPPLIER_OWNER.key,
        displayName: STRINGS.columns.purchase.SUPPLIER_OWNER.name,
        checked: true,
        type: FilterTypes.FILTER_TEXT,
        selectable: true,
        disabled: false,
      },
      [STRINGS.columns.purchase.SUPPLIER_CUTOFF.key]: {
        name: STRINGS.columns.purchase.SUPPLIER_CUTOFF.key,
        displayName: STRINGS.columns.purchase.SUPPLIER_CUTOFF.name,
        checked: true,
        type: FilterTypes.FILTER_TEXT,
        selectable: true,
        disabled: false,
      },
      [STRINGS.columns.purchase.TOTAL_WEIGHT.key]: {
        name: STRINGS.columns.purchase.TOTAL_WEIGHT.key,
        displayName: STRINGS.columns.purchase.TOTAL_WEIGHT.name,
        checked: true,
        type: FilterTypes.FILTER_NUMBER,
        selectable: true,
        disabled: false,
      },
      [STRINGS.columns.purchase.TOTAL_VOLUME.key]: {
        name: STRINGS.columns.purchase.TOTAL_VOLUME.key,
        displayName: STRINGS.columns.purchase.TOTAL_VOLUME.name,
        checked: true,
        type: FilterTypes.FILTER_NUMBER,
        selectable: true,
        disabled: false,
      },
      [STRINGS.columns.purchase.MINIMUM_ORDER_VALUE.key]: {
        name: STRINGS.columns.purchase.MINIMUM_ORDER_VALUE.key,
        displayName: STRINGS.columns.purchase.MINIMUM_ORDER_VALUE.name,
        checked: true,
        type: FilterTypes.FILTER_NUMBER,
        selectable: true,
        disabled: false,
      },
    },
  },
  [COLUMNS_GROUPS.FIXED_END.key]: {
    groupKey: COLUMNS_GROUPS.FIXED_END.key,
    groupName: COLUMNS_GROUPS.FIXED_END.name,
    columns: {
      [STRINGS.columns.purchase.ACTIONS.key]: {
        name: STRINGS.columns.purchase.ACTIONS.key,
        displayName: STRINGS.columns.purchase.ACTIONS.name,
        checked: true,
        type: FilterTypes.FILTER_TEXT,
        selectable: false,
        disabled: false,
      },
    },
  },
};

export const columnsGroupsMap: Map<string, string> =
  buildGroupsMap(defaultColumnsV2);

export const defaultFiltersV2: DatatableColumnV2Groups<Filter> = {
  [COLUMNS_GROUPS.ORDER.key]: {
    groupKey: COLUMNS_GROUPS.ORDER.key,
    groupName: COLUMNS_GROUPS.ORDER.name,
    columns: {
      [STRINGS.columns.purchase.ORDER_MOMENT_ID.key]: {
        name: STRINGS.columns.purchase.ORDER_MOMENT_ID.name,
        key: STRINGS.columns.purchase.ORDER_MOMENT_ID.filterKey,
        uiModel: FilterTypes.FILTER_NUMBER,
        validations: {
          min: 1,
          max: null,
          step: 1,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.INTEGER,
          operator: null,
          subOperator: ServiceRequestInfoV3Operators.AND,
          value: null,
        },
        disabled: false,
        nullable: true,
        sharedFilter: true,
      },
      [STRINGS.columns.purchase.TYPE.key]: {
        name: STRINGS.columns.purchase.TYPE.name,
        key: STRINGS.columns.purchase.TYPE.filterKey,
        uiModel: FilterTypes.FILTER_LIST,
        options: [
          {
            key: STRINGS.metadata.purchase.orderMomentTypes.regular,
            value: '0',
          },
          {
            key: STRINGS.metadata.purchase.orderMomentTypes.regularPreponed,
            value: '1',
          },
          {
            key: STRINGS.metadata.purchase.orderMomentTypes.lostSales,
            value: '2',
          },
          {
            key: STRINGS.metadata.purchase.orderMomentTypes.backorder,
            value: '3',
          },
        ],
        validations: {
          min: null,
          max: null,
          step: null,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.INTEGER,
          operator: FilterTypeOptionsV2.EQUALS,
          subOperator: ServiceRequestInfoV3Operators.OR,
          value: null,
        },
        disabled: false,
        sharedFilter: true,
      },
      [STRINGS.columns.purchase.TRIGGER_VALUE.key]: {
        name: STRINGS.columns.purchase.TRIGGER_VALUE.name,
        key: STRINGS.columns.purchase.TRIGGER_VALUE.filterKey,
        uiModel: FilterTypes.FILTER_NUMBER,
        validations: {
          min: 1,
          max: null,
          step: 0.01,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.FLOAT,
          operator: null,
          subOperator: ServiceRequestInfoV3Operators.AND,
          value: null,
        },
        disabled: false,
        sharedFilter: false,
      },
      [STRINGS.columns.purchase.PLANNED_DATE.key]: {
        name: STRINGS.columns.purchase.PLANNED_DATE.name,
        key: STRINGS.columns.purchase.PLANNED_DATE.filterKey,
        uiModel: FilterTypes.FILTER_DATE,
        validations: {
          min: null,
          max: null,
          step: null,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.DATETIME,
          operator: null,
          subOperator: ServiceRequestInfoV3Operators.AND,
          value: null,
        },
        disabled: false,
        sharedFilter: false,
      },
      [STRINGS.columns.purchase.STATUS.key]: {
        name: STRINGS.columns.purchase.STATUS.name,
        key: STRINGS.columns.purchase.STATUS.filterKey,
        uiModel: FilterTypes.FILTER_LIST,
        options: [
          {
            key: STRINGS.metadata.purchase.orderStatus.overdue,
            value: ProposedOrdersStatus.OVERDUE,
          },
          {
            key: STRINGS.metadata.purchase.orderStatus.today,
            value: ProposedOrdersStatus.TODAY,
          },
          {
            key: STRINGS.metadata.purchase.orderStatus.future,
            value: ProposedOrdersStatus.FUTURE,
          },
        ],
        validations: {
          min: null,
          max: null,
          step: null,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.STRING,
          operator: null,
          subOperator: ServiceRequestInfoV3Operators.OR,
          value: null,
        },
        disabled: false,
        sharedFilter: false,
      },
      [STRINGS.columns.purchase.NUMBER_OF_PRODUCTS.key]: {
        name: STRINGS.columns.purchase.NUMBER_OF_PRODUCTS.name,
        key: STRINGS.columns.purchase.NUMBER_OF_PRODUCTS.filterKey,
        uiModel: FilterTypes.FILTER_NUMBER,
        validations: {
          min: 0,
          max: null,
          step: 1,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.INTEGER,
          operator: null,
          subOperator: ServiceRequestInfoV3Operators.AND,
          value: null,
        },
        disabled: false,
        sharedFilter: true,
      },
      [STRINGS.columns.purchase.TOTAL_VALUE.key]: {
        name: STRINGS.columns.purchase.TOTAL_VALUE.name,
        key: STRINGS.columns.purchase.TOTAL_VALUE.filterKey,
        unit: STRINGS.columns.purchase.TOTAL_VALUE.unit,
        uiModel: FilterTypes.FILTER_NUMBER,
        validations: {
          min: 0.01,
          max: null,
          step: 0.01,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.FLOAT,
          operator: null,
          subOperator: ServiceRequestInfoV3Operators.AND,
          value: null,
        },
        disabled: false,
        sharedFilter: true,
      },
    },
  },
  [COLUMNS_GROUPS.SUPPLIER.key]: {
    groupKey: COLUMNS_GROUPS.SUPPLIER.key,
    groupName: COLUMNS_GROUPS.SUPPLIER.name,
    columns: {
      [STRINGS.columns.purchaseEditor.NOTES.key]: {
        name: STRINGS.columns.purchaseEditor.NOTES.name,
        key: STRINGS.columns.purchaseEditor.NOTES.filterKey,
        uiModel: FilterTypes.FILTER_TEXT,
        validations: {
          min: null,
          max: null,
          step: null,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.STRING,
          operator: null,
          subOperator: ServiceRequestInfoV3Operators.AND,
          value: null,
        },
        disabled: false,
        partialMatch: true,
      },
      [STRINGS.columns.purchaseEditor.HAS_NOTES.key]: {
        name: STRINGS.columns.purchaseEditor.HAS_NOTES.name,
        key: STRINGS.columns.purchaseEditor.HAS_NOTES.filterKey,
        uiModel: FilterTypes.FILTER_NULLABLE,
        validations: {
          min: null,
          max: null,
          step: null,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.NULLABLE,
          operator: FilterTypeOptionsV2.IS_NULL,
          subOperator: ServiceRequestInfoV3Operators.AND,
          value: null,
        },
        disabled: false,
      },
      [STRINGS.columns.purchase.SUPPLIER_NAME.key]: {
        name: STRINGS.columns.purchase.SUPPLIER_NAME.name,
        key: STRINGS.columns.purchase.SUPPLIER_NAME.filterKey,
        uiModel: FilterTypes.FILTER_TEXT,
        validations: {
          min: null,
          max: null,
          step: null,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.STRING,
          operator: FilterTypeOptionsV2.EQUALS,
          subOperator: ServiceRequestInfoV3Operators.AND,
          value: null,
        },
        disabled: false,
        sharedFilter: true,
      },
      [STRINGS.columns.purchase.SUPPLIER_OWNER.key]: {
        name: STRINGS.columns.purchase.SUPPLIER_OWNER.name,
        key: STRINGS.columns.purchase.SUPPLIER_OWNER.filterKey,
        uiModel: FilterTypes.FILTER_TEXT,
        validations: {
          min: null,
          max: null,
          step: null,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.STRING,
          operator: FilterTypeOptionsV2.EQUALS,
          subOperator: ServiceRequestInfoV3Operators.AND,
          value: null,
        },
        disabled: false,
        sharedFilter: true,
      },
      [STRINGS.columns.purchase.SUPPLIER_CUTOFF.key]: {
        name: STRINGS.columns.purchase.SUPPLIER_CUTOFF.name,
        key: STRINGS.columns.purchase.SUPPLIER_CUTOFF.filterKey,
        uiModel: FilterTypes.FILTER_TIME,
        validations: {
          min: null,
          max: null,
          step: null,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.TIME,
          operator: null,
          subOperator: ServiceRequestInfoV3Operators.AND,
          value: null,
        },
        disabled: false,
        sharedFilter: true,
      },
      [STRINGS.columns.purchase.TOTAL_WEIGHT.key]: {
        name: STRINGS.columns.purchase.TOTAL_WEIGHT.name,
        key: STRINGS.columns.purchase.TOTAL_WEIGHT.filterKey,
        unit: STRINGS.columns.purchase.TOTAL_WEIGHT.unit,
        uiModel: FilterTypes.FILTER_NUMBER,
        validations: {
          min: 0.01,
          max: null,
          step: 0.01,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.FLOAT,
          operator: null,
          subOperator: ServiceRequestInfoV3Operators.AND,
          value: null,
        },
        disabled: false,
        sharedFilter: true,
      },
      [STRINGS.columns.purchase.TOTAL_VOLUME.key]: {
        name: STRINGS.columns.purchase.TOTAL_VOLUME.name,
        key: STRINGS.columns.purchase.TOTAL_VOLUME.filterKey,
        unit: STRINGS.columns.purchase.TOTAL_VOLUME.unit,
        uiModel: FilterTypes.FILTER_NUMBER,
        validations: {
          min: 0.01,
          max: null,
          step: 0.01,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.FLOAT,
          operator: null,
          subOperator: ServiceRequestInfoV3Operators.AND,
          value: null,
        },
        disabled: false,
        sharedFilter: true,
      },
      [STRINGS.columns.purchase.MINIMUM_ORDER_VALUE.key]: {
        name: STRINGS.columns.purchase.MINIMUM_ORDER_VALUE.name,
        key: STRINGS.columns.purchase.MINIMUM_ORDER_VALUE.filterKey,
        unit: STRINGS.columns.purchase.MINIMUM_ORDER_VALUE.unit,
        uiModel: FilterTypes.FILTER_NUMBER,
        validations: {
          min: 0.01,
          max: null,
          step: 0.01,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.FLOAT,
          operator: null,
          subOperator: ServiceRequestInfoV3Operators.AND,
          value: null,
        },
        disabled: false,
        sharedFilter: true,
      },
    },
  },
};

export const filtersGroupsMap: Map<string, string> =
  buildGroupsMap(defaultFiltersV2);

export const defaultPagination: DatatablePagination = {
  page: 0,
  size: 50,
  pageSizeOptions: [10, 15, 25, 50, 75, 100, 125, 150, 200],
};

export const overdueFilter: ColumnFilterParam = {
  columnKey: 'STATUS',
  optionSelected: null,
  subOperator: ServiceRequestInfoV3Operators.AND,
  values: [
    {
      key: 'Overdue',
      value: ProposedOrdersStatus.OVERDUE,
    },
  ],
  applyAll: false,
};

export const todayOrdersFilter: ColumnFilterParam = {
  columnKey: 'STATUS',
  optionSelected: null,
  subOperator: ServiceRequestInfoV3Operators.AND,
  values: [
    {
      key: 'Today',
      value: ProposedOrdersStatus.TODAY,
    },
  ],
  applyAll: false,
};

export const unplanedFilter: ColumnFilterParam = {
  columnKey: 'ORDER_MOMENT_ID',
  optionSelected: FilterTypes.FILTER_IS_NULL,
  subOperator: ServiceRequestInfoV3Operators.AND,
  values: {
    to: null,
  },
  applyAll: false,
};

export const PURCHASE_FILTERS = {
  OVERVIEW_SUMMARY_OVERDUE_ORDERS: [overdueFilter],
  OVERVIEW_SUMMARY_TODAYS_ORDERS: [todayOrdersFilter],
  OVERVIEW_SUMMARY_UNPLANNED_ORDERS: [unplanedFilter],
};
